<template>
  <el-dialog class="popup_dialog" :title="fileTitle" :visible.sync="isFilePopup" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
    <div class="file-list" style="line-height: 25px;">
      <div v-for="(item, index) in fileList" :key="index">
        <i class="el-icon-document"></i>
        <span class="ml16">{{item.name}}</span>
        <a href="javascript:void(0)" @click="download(imageURL + item.url, item.name)"><i class="el-icon-download ml16" style="color: #5DAD03; font-weight: bolder;"></i></a>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('closePopup')" size="small">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {imageURL} from "../utils/config";
import {download} from '../utils/method';

export default {
  name: "filePopup",
  data () {
    return {
      imageURL: imageURL,
      download: download
    }
  },
  props: {
    isFilePopup: {
      type: Boolean,
      default: false
    },
    fileTitle: {
      type: String,
      default: ''
    },
    fileList: {
      type: Array,
      default: ()=>{return []}
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
